import React, { Fragment } from "react";

import Carousel from "./Carousel";

const Home = () => {
  return (
    <Fragment>
      <Carousel />
    </Fragment>
  );
};

export default Home;
